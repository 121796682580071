import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "בתחום הנדסת התוכנה יש מספר מתודולוגיות פיתוח תוכנה המבוססות על סט ידוע ומוסכם של עקרונות, כלים, תהליכים שונים ופרקטיקות. מתודולוגיות הפיתוח בתחום התוכנה נבדלות זו מזו במגוון היבטים. כדאי לדעת כי עקרונות הפיתוח בתעשיית האונליין וההייטק הועתקו למעשה מתעשיות קלאסיות ובעיקר ממפעלי הייצור התעשייתיים, בהם מקובל לעבוד לפי מודל הנקרא מפל מים, באנגלית Waterfall.",
      "במודל מפל מים חברת פיתוח תוכנה מפתחת את המערכת בתהליך לוגי ושיטתי המבוסס על שלבים מוגדרים מראש, ויש לבצע את כל השלבים, ובכל שלב בתהליך הפיתוח מתמקדים במשימה מרכזית אחת. השלבים המרכזיים בתהליך פיתוח במודל מפל מים הם איסוף נתונים ומידע, ניתוח דרישות הלקוח ומאפייני התוכנה, עיצוב התוכנה, פיתוח בשפות תכנות, בדיקות, אינטגרציה ושילוב מערכות, התקנה ותחזוקה.",
      "מודל זה שימש בתחילת הדרך את תעשיית ההייטק אך הוא אינו מתאים לפיתוח תוכנה על ידי צוותים קטנים, ובתי התוכנה חיפשו מתודולוגיות גמישות ועדכניות יותר, וכך למעשה החלו חוקרים ואנשי פיתוח מיומנים המתמחים בחקר מערכות תוכנה וביישומן לקבוע עקרונות יסוד חדישים וגמישים יותר לפיתוח זריז של תוכנות ומערכות ווב, והמתודולוגיה הזריזה לפיתוח תוכנה נקראת אג'ייל Agile. פיתוח לפי מתודולוגיית Agile מבוסס על עקרון יסוד נוסף של שיתוף פעולה הדוק ומיטבי בין צוותי הפיתוח הקטנים לבין הלקוחות. במודל זה מקפידים על תקשורת פרונטלית (פנים אל פנים) גם עם ההנהלה, וכאמור גם עם הלקוחות, עובדים בצוותים קטנים, מנהלים בנק משימות ומוסרים באופן תכוף את הקוד ובספרינטים, כלומר בפרקי זמן קבועים וקצרים."
    ],
  },
  section2: {
    h: "פיתוח תוכנה זריז",
    p: [
      "באמצע שנות ה-90 של המאה הקודמת פותחה מתודולוגיית פיתוח תוכנה חדשה וזריזה, במודל זה יישמו את העקרונות של ייצור רזה בתחום התוכנה, באנגלית Lean, והמתודולוגיה החדשנית זכתה לשם המקורי סקראם (Scrum), כאשר מקור הביטוי הוא ממשחק הרוגבי. המונה מתאר מצב שבו הקבוצות נערכות זו מול זו במטרה להשיג את הכדור בעזרת משחק מסירות במחזורים קצרים.",
      "מתודולוגיית הפיתוח Scrum מבוססת על הנחת היסוד שטוענת כי פיתוח תוכנה הוא בעצם בעיה אמפירית, לכן אין אפשרות לפתור את הבעיה בשיטות מסורתיות המבוססות של חיזוי או תכנון מאחר ואין אפשרות להגדיר מראש את הפיתוח של המערכת. לכן, המתודולוגיה לפיתוח התוכנה ב- Scrum מתמקדת בשיפור היכולת של צוות הפיתוח כדי לאפשר להם לספק את התוצרים במהירות, ובנוסף צוותי הפיתוח יכולים להגיב לדרישות המשתנות שעולות במהלך תהליך הפיתוח. במודל זה הצוותים מנווטים את תהליך הפיתוח של התוכנה בצורה עצמאית, ומחזורי הפיתוח זריזים יותר."
    ],
  },
  section3: {
    h: "פיתוח תוכנה לפי עקרונות של מתודולוגיות שונות",
    p: [
      "ראוי לציין כי יש עשרות ואפילו מאות מתודולוגיות לפיתוח תוכנה, כאשר תהליך הפיתוח בהגדרתו מוגדר כיצירה של אוסף תוכנות ייעודיות למחשב שנועדו לאפשר יישום כלשהו. בפיתוח תוכנה מפתחים מוצר חדש בהתאם לצרכי הלקוח או מטרה הנקבעת מראש, כאשר התוכנה מורכבת מאוסף של תוכנות מחשב ובעזרת רצף הוראות מתבצעת פעולה מסוימת במחשב.",
      "יש 3 משפחות עיקריות של מתודולוגיות פיתוח:",
    ],
    ol: [
      "1. קוויות",
      "2. איטרטיביות",
      "3. זריזות (Agile/Scrum)"
    ]
  },
  section4: {
    p: [
      "מתודולוגיית Agile נחשבת לשיטה החדישה, היא מתאימה יותר לרוח התקופה ולדרישות השוק בענף ההייטק, אך ההטמעה שלה עשויה להיות מורכבת יחסית. העקרונות המרכזיים לפי מתודולוגיית Agile או Scrum הם:"
    ],
    li: [
      <>
        <strong>תקשורת ויחסי גומלין</strong>
        {` – תקשורת בין אנשים ויחסי גומלין חשובים לקידום הפרויקט ויש להתמקד בתקשורת כדי לקדם את תהליך הפיתוח, ללא קשר לכלים ולטכנולוגיות הנבחרות. צוות הפיתוח עובד בשיתוף פעולה עם הלקוח של חברת פיתוח תוכנה.`}
      </>,
      <>
        <strong>תוכנה עובדת</strong>
        {` – במודל הפיתוח Agile החלק החשוב ביותר הוא תוכנה עובדת ויש לתת לכך עדיפות על פני תיעוד מוקפד כפי שנהוג בשיטות אחרות.`}
      </>,
      <>
        <strong>שינויים הם חלק מתהליך הפיתוח</strong>
        {` – במתודולוגית המסורתיות המפתחים נצמדו לתוכנית המקורית, לעומת Agile שבה פתוחים לשינויים ומבינים שהם חלק בלתי נפרד מהתהליך, לכן צוותי הפיתוח שומרים על גמישות ומבצעים שינויים בתוכנה במהירות.`}
      </>,
      <>
        <strong>ספרינטים וצוותים עצמאיים</strong>
        {` – במתודולוגיית Scrum בונים צוותי פיתוח המקבלים עצמאות מלאה בתהליך, וסבבי הפיתוח הם קצרים, לכן הם מכונים ספרינטים.`}
      </>
    ],
  },
  section5: {
    p: [
      <>
        <Link to={URL.HOME} active>בית התוכנה אפספורס</Link>,
        {` מתמחה בפיתוח תוכנות, אפליקציות ומערכות WEB, לפי מתודולוגיות שונות, ובכל פרויקט אנחנו קובעים את מודל הפיתוח בהתאם למוצר והעדפות צוות הפיתוח והלקוחות. בכל פרויקט לפיתוח תוכנה אנחנו מתמקדים במרכיב האנושי המהווה חלק חשוב ובלתי נפרד במהלך העבודה, לכן אנו דואגים לבסס קשר ישיר המבוסס על שיתוף פעולה רציף והדוק עם הלקוח, בשקיפות מלאה, ובמטרה לפתח מוצר טכנולוגי העונה באופן מושלם על צרכי ודרישות הלקוח. למידע נוסף וייעוץ בנושא שלבי ותהליכי הפיתוח אצלנו - הנכם `}
        <Link to={URL.CONTACT} active>מוזמנים ליצור איתנו קשר</Link>.
      </>
    ]
  }
};