import * as React from "react";
import { graphql } from 'gatsby';
import Blog, {
  BlogSubHeader, BlogList, BlogListItem, BlogUnorderList, BlogUnorderListItem
} from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/stages-on-agile-development';
import { SolutionsForSoftwareDevelopment, BenefitsOfWorkingWithSoftwareCompany, StagesOnAgileDevelopment, SoftwareDevelopmentToolsThatMustBeFamiliar } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const title = "Stages on agile development";

const articleSchemaData = {
  headline: title,
  description: 'SEO description stages on agile development',
  image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fstages-on-agile-development.jpg?alt=media&token=52ece246-d7c0-4ff8-a747-a7641cb29001',
  datePublished: '2022-02-20'
};


const StagesOnAgileDevelopmentBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={StagesOnAgileDevelopment.url}
      banner="/stages-on-agile-development/banner.jpg"
      relatedBlogs={[BenefitsOfWorkingWithSoftwareCompany, SolutionsForSoftwareDevelopment, SoftwareDevelopmentToolsThatMustBeFamiliar]}
      title={title}
      seo={{
        title: 'SEO title stages on agile development',
        description: 'SEO description stages on agile development',
        image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fstages-on-agile-development.jpg?alt=media&token=52ece246-d7c0-4ff8-a747-a7641cb29001',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section2.h}
      </BlogSubHeader>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>
      {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogList>
        {HE.section3.ol.map(p => <BlogListItem><Paragraph>{p}</Paragraph></BlogListItem>)}
      </BlogList>

      {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}

      <Paragraph>
        <BlogUnorderList>
          {HE.section4.li.map(li => <BlogUnorderListItem>{li}</BlogUnorderListItem>)}
        </BlogUnorderList>
      </Paragraph>

      {HE.section5.p.map(p => <Paragraph>{p}</Paragraph>)}
    </Blog>
  )
}

export default StagesOnAgileDevelopmentBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;